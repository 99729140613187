import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="wizard"
export default class extends Controller {
  static values = {
    step: Number,
  };

  connect() {
    this.updateLocation();

    if (this.stepValue > 0 && !window.wizardRefreshed) {
      let top = this.element.getBoundingClientRect().top;

      if (this.mobile()) {
      } else {
        if (top > 0) top -= document.querySelector(".header").clientHeight + 40;
      }

      window.scrollBy({
        top: top,
        behavior: "smooth",
      });
    }

    window.wizardRefreshed = false;
  }

  mobile() {
    return document.body.clientWidth < 768;
  }

  updateLocation() {
    //window.history.replaceState({}, "", "?s=" + this.stepValue);

    window.location.hash = `step-${this.stepValue}`;
  }

  refresh() {
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = "_refresh";

    this.element.append(input);

    window.wizardRefreshed = true;

    this.element.requestSubmit();
  }

  check() {}
}
