import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="json"
export default class extends Controller {
  connect() {
    this.prettify();

    this.element.addEventListener("input", () => {
      var start = this.element.selectionStart,
        end = this.element.selectionEnd;

      this.prettify();

      // restore from variables...
      this.element.setSelectionRange(start, end);
    });
  }

  prettify() {
    this.element.value = JSON.stringify(
      JSON.parse(this.element.value),
      null,
      4
    );
  }
}
