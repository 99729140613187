import { Controller } from "@hotwired/stimulus";

var clickOutside = require("click-outside");

export default class extends Controller {
  static targets = ["modal", "frame"];

  connect() {
    document.addEventListener("turbo:before-fetch-request", (e) => {
      if (e.detail.fetchOptions.headers["Turbo-Frame"] == "modal") {
        this.open();
      }
    });

    window.addEventListener("hashchange", () => {
      let hash = window.location.hash;

      if (!hash.includes('/')) return;

      let element = document.createElement("a");

      element.href = hash.replace("#", "/");
      element.dataset.turboFrame = "modal";
      document.body.append(element);
      element.click();
      element.remove();
    });

    let cookie = this.getCookie('modal')

    if(cookie){
      let parts = cookie.split('|');

      if(parts[1] == this.getPath())
        window.scrollTo(0, parts[0]);

      document.cookie = "modal= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
    }
  }

  close(e) {
    if (e.force || e.currentTarget == e.target) {
      if(!e.force) e.preventDefault();

      this.data.delete("open");
      this.frameTarget.innerHTML = "";
    }
  }

  open() {
    let scroll = window.scrollY;

    document.cookie = `modal=${scroll}|${this.getPath()};`

    this.frameTarget.innerHTML = "";

    this.data.set("open", "1");

    this.eventListener = clickOutside(this.frameTarget, (e) => {
      this.close({force: true});

      this.eventListener();
    });

    // fetch(event.currentTarget.getAttribute('href'))
    // 	.then(response => response.text())
    // 	.then(html => {
    // 		this.containerTarget.innerHTML = html
    // 	}
    // )
  }

  getPath() {
    return window.location.pathname.replace(/[^A-Za-z0-9]/g, '')
  }

  getCookie(name){
    let result = document.cookie.split(";").find(e => e.includes('modal'));

    if(result)
      return result.split('=')[1];
    
    return false
  }
}
