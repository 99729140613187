import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="element"
export default class extends Controller {
  static targets = ["element"];

  connect() {}

  reveal(e) {
    e.preventDefault();

    e.target.remove();

    this.elementTargets.forEach((element) => {
      element.classList.remove("d-none");
    });
  }
}
