import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="turbo"
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:before-stream-render", function (event) {
      event.preventDefault();

      let oldElement = document.getElementById(event.target.target);

      oldElement.classList.add("animate__fadeOutLeft", "animate__animated");

      console.log(event.detail.newTemplate);

      oldElement.addEventListener("animationend", () => {
        oldElement.replaceWith(event.detail.newBody);
      });
    });
  }
}
