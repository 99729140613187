import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="loader"
export default class extends Controller {
  static targets = ["frame", "loading"];

  static values = {
    url: String,
    includes: String,
  };

  connect() {
    this.frame().addEventListener("turbo:frame-load", this.load.bind(this));

    this.timeout();
  }

  timeout() {
    setTimeout(this.refresh.bind(this), 1500);
  }

  frame() {
    return this.element.querySelector("turbo-frame");
  }

  refresh() {
    this.frame().src = null;
    this.frame().src = this.urlValue;
  }

  load() {
    if (this.frame().innerHTML.includes(this.includesValue)) {
      this.loadingTarget.remove();
    } else {
      this.timeout();
    }
  }
}
