import { Controller } from "@hotwired/stimulus";
import debounce from "debounce";

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["q"];

  connect() {
    this.submitWithDebounce = debounce(this.submitWithDebounce.bind(this), 300);
  }

  submit(event) {
    this.element.requestSubmit();
  }

  submitWithDebounce() {
    this.submit();
  }
}
