import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="input"
export default class extends Controller {
  static classes = ["active", "inactive"];

  static values = {
    initialize: String,
    post: Boolean,
  };

  connect() {
    if (this.hasInitializeValue) {
      this[this.initializeValue]();
    }

    this.updateCheck();
  }

  updateCheck(e) {
    this.element.querySelectorAll("input").forEach((element) => {
      let parentElement = element.closest('[data-input-target="parent"]');

      if (element.checked) {
        parentElement.classList.remove(this.inactiveClass);
        parentElement.classList.add(this.activeClass);
      } else {
        parentElement.classList.remove(this.activeClass);
        parentElement.classList.add(this.inactiveClass);
      }
    });

    if (e && this.hasPostValue) {
      this.submit();
    }
  }

  submit() {
    let direction = document.createElement("input");
    direction.name = "direction";
    direction.value = "next";
    direction.type = "hidden";
    this.element.closest("form").appendChild(direction);

    this.element
      .closest("form")
      .requestSubmit(document.querySelector("button"));
  }

  submitFirst() {
    this.element.querySelector('input[type="radio"]').checked = true;

    this.submit();
  }
}
