import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="page"
export default class extends Controller {
  connect() {
    document.querySelectorAll('a[href^="http"]').forEach( (element) => {
      element.setAttribute('target', '_blank');
    })

    document.querySelectorAll('a[href^="https://www.youtube"]').forEach( (element) => {
      if(window.innerWidth > 700) {
        element.removeAttribute('target');
        element.dataset.turboFrame = 'modal';
        element.href = '/video?url=' + element.href;
      }
    })
  }
}